import axios from "axios"
import { message } from 'antd';
import { store } from '../store';
import PubSub from 'pubsub-js'

type RequestConfig = {
    url: string
    params?: any
    data?: any
}

const instance = axios.create({
    method: 'post',
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 60 * 1000,
})

// 请求拦截器
instance.interceptors.request.use(function (config) {
    const currentUserToken = store.getState().currentUser.authInfo?.token
    // 用户已登录, 请求带上token
    if (currentUserToken) {
        config.params.token = currentUserToken
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error.response);
});

// 验证当前用户 token 是否有效
const verifyToken = () => {
    return new Promise((resolve, reject) => {
        const authInfo = store.getState().currentUser.authInfo
        // 判断token是否有效
        if (authInfo?.token) {
            // 验证token是否过期
            const diff = (Date.now() - authInfo.time) / 1000 / 60; // 分钟
            console.log(`token: ${parseInt(diff.toString())}m`);
            if (diff >= 60 && diff < 120) { // token即将过期
                // 更新token
                instance.request({
                    url: "Token/RenewToken",
                    params: {},
                })
                    .then((res: any) => {
                        if (res.IsSuccess) {
                            // 发生刷新登录 token 事件
                            PubSub.publish('RefreshAuthInfoEvent', {
                                token: res.Data.token,
                                time: Date.now(),
                            })
                        }
                    })
                    .finally(() => {
                        resolve("token刷新完成");
                    });
            } else if (diff >= 120) { // token过期
                reject("token已过期")
            } else {
                resolve("token未过期")
            }
        } else {
            reject("未登录")
        }
    })
}

export const post = (config: RequestConfig, silent?: boolean): Promise<any> => {
    // 设置默认params
    config.params || (config.params = {})
    // 设置默认data
    config.data || (config.data = {})
    return new Promise((resolve, reject) => {
        instance.request(config)
            .then((data: any) => {
                if (!data.IsSuccess && data.Msg == "token验证失败") {
                    reject(data.Msg)
                    // 发送用户退出事件
                    PubSub.publish('UserLogoutEvent')
                } else {
                    resolve(data);
                }
            })
            .catch((error: any) => {
                if (!silent) {
                    message.destroy()
                    if (error.status >= 400 && error.status < 500) { // 客户端错误
                        message.error('请求出错了！');
                    } else if (error.status >= 500) { // 服务器端错误
                        message.error('服务器出错了！');
                    }
                }
                reject(error.status)
            })
            .finally(() => {
                verifyToken()
                    .catch(() => {
                        // 发送用户退出事件
                        PubSub.publish('UserLogoutEvent')
                    })
            })
    })
}

export default instance