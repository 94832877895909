import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Menu, Modal, Form, Input, Button, notification } from 'antd';
import type { MenuProps } from 'antd';
import { useAppSelector } from '../../../store/hook'
import PlaceholderAvatar from "../../../assets/images/placeholder-avatar.png";
import { post } from "../../../utils/axios"
import _find from 'lodash/find';
import md5 from "js-md5";

// 下拉菜单
const DropdownMenu = () => {
    const userInfo = useAppSelector(state => state.currentUser.userInfo)
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false); // 是否显示重置密码对话框
    const [loading, setLoading] = useState(false)
    const bpmSystemList = useAppSelector(state => state.bpmSystem.list)
    // 下拉菜单项
    const [menuItems, setMenuItems] = useState<MenuProps['items']>([])

    useEffect(() => {
        const baseMenus: MenuProps['items'] = [
            {
                type: "divider"
            },
            {
                key: 'ResetPassword',
                label: '重设密码',
            },
            {
                key: 'Logout',
                label: '退出',
            },
        ]

        setMenuItems([
            ...bpmSystemList.map((item) => ({
                key: item.id,
                label: item.name,
            })),
            ...baseMenus,
        ])
    }, [bpmSystemList])

    // 点击下拉菜单项 
    const handleMenuItemClick = (key: string) => {
        switch (key) {
            case 'Logout':
                // 发送用户退出事件
                PubSub.publish('UserLogoutEvent')
                break;
            case 'ResetPassword':
                // 重置密码
                setIsModalVisible(true)
                break;
            default: // 打开BPM
                const bpm = _find(bpmSystemList, { id: key })
                window.open(bpm?.url, '_blank');
                break;
        }
    }

    // 重置密码
    const handelResetPassword = (values: any) => {
        const { oldPassword, newPassword } = values

        setLoading(true)
        post({
            url: 'Employee/ModifyPsw',
            params: {
                OldPsw: md5(oldPassword),
                NewPlsw: md5(newPassword),
            }
        })
            .then(res => {
                if (res.IsSuccess) {
                    notification.success({
                        message: '提示',
                        description: '密码修改成功'
                    })
                    // 关闭对话框
                    setIsModalVisible(false)
                } else {
                    notification.error({
                        message: '提示',
                        description: res.Msg
                    })
                    // 清空旧密码
                    form.resetFields(['oldPassword'])
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Dropdown
                placement="bottom"
                arrow
                overlay={
                    <Menu
                        style={{ "width": "170px" }}
                        onClick={(info) => handleMenuItemClick(info.key)}
                        items={menuItems}
                    />
                }
            >
                <span style={{ "cursor": "pointer" }}>
                    <img src={PlaceholderAvatar} />&nbsp;&nbsp;{userInfo?.RealName}
                </span>
            </Dropdown>

            {/* 重设密码对话框 */}
            <Modal
                title="重设密码"
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form
                    form={form}
                    onFinish={handelResetPassword}
                    requiredMark={false}
                >
                    <Form.Item
                        label="旧密码"
                        name="oldPassword"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password placeholder='请输入旧密码' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password placeholder='请输入新密码' autoComplete='off' />
                    </Form.Item>

                    <Form.Item style={{ "textAlign": "right" }} >
                        <Button
                            htmlType="reset"
                            onClick={() => setIsModalVisible(false)}
                        >取消</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ "marginLeft": "20px" }}
                            loading={loading}
                        >修改密码</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { state } = useLocation()
    const [currentPath, setCurrentPath] = useState<string>(); // 当前页面路径

    useEffect(() => {
        const { path } = (state as any) || {}
        path ? setCurrentPath(path) : setCurrentPath(pathname)
    }, [pathname, state])

    return (
        <div className="page-header">
            <div className="menubar">
                <ul className="menubar-list">
                    <li className="menubar-list__item">
                        <DropdownMenu />
                    </li>
                </ul>
            </div>

            <div className="navbar">
                <ul className="navbar-list">
                    <li
                        className={currentPath === '/' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/')}
                    >首页</li>
                    <li
                        className={currentPath?.indexOf('/workbench') !== -1 ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/system', {
                            state: { path: '/workbench' }
                        })}
                    >审批中心</li>
                    <li
                        className={currentPath === '/report' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/system', {
                            state: { path: '/report' }
                        })}
                    >数据中心</li>
                    <li
                        className={currentPath === '/board' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/board')}
                    >超级看板</li>
                    <li
                        className={currentPath === '/file' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/file')}
                    >信息中心</li>
                </ul>
            </div>
        </div>
    )
}