import { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Checkbox, notification } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { useNavigate } from "react-router-dom";
import "./index.less";
import { post } from "../../../utils/axios"
import { setLocalStorage, getLocalStorage, clearLocalStorage } from "../../../utils/index"
import { useAppDispatch } from '../../../store/hook'
import { setAuthInfo, delAuthInfo, setUserInfo } from '../../../store/slices/currentUser'
import PubSub from 'pubsub-js'
import md5 from "js-md5";
import Logo from './../../../assets/images/logo-1.png';

export default () => {
    const [loading, setLoading] = useState(false) // 是否登录中
    const [rememberMe, setRememberMe] = useState(false) // 记住我
    const [form] = Form.useForm();
    const formRef = useRef<FormInstance>(null);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        // 是否记住我
        const data = getLocalStorage('remember_me')
        if(formRef.current && data) {
            setRememberMe(true)
            formRef.current.setFieldsValue({
                username: data.username,
                password: data.password,
            })
        }
    }, [formRef])

    // 提交登录表单
    const onFinish = (values: any) => {
        const { username, password } = values

        setLoading(true)
        post({
            url: '/Token/GetToken',
            params: {
                username, 
                password: md5(password)
            }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const token = res.Data.token
                    // 本地存储token
                    dispatch(setAuthInfo({ token, time: Date.now() }))
                    // 获取用户信息
                    return post({
                        url: 'Employee/GetCurUserInfo',
                        params: { token }
                    })
                } else {
                    notification.error({
                        message: '提示',
                        description: res.Msg,
                    })
                    form.resetFields()
                    return Promise.reject('获取token失败');
                }
            })
            .then(res => {
                if (res.IsSuccess) {
                    // 存储登录用户信息
                    dispatch(setUserInfo({ RealName: res.Data.RealName }))
                    // 跳转到首页
                    navigate('/')
                    // 发送用户登录事件
                    PubSub.publish('UserLoginEvent')
                    // 记住账号密码
                    if (rememberMe) {
                        setLocalStorage('remember_me', { username, password })
                    } else {
                        clearLocalStorage('remember_me')
                    }
                } else {
                    notification.error({
                        message: '提示',
                        description: '登录失败',
                    })
                    // 获取失败，清除本地token
                    dispatch(delAuthInfo())
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className='login'>
            <div className='layer-top'>
                <img className='logo' src={Logo} alt="logo" />
            </div>

            <div className='layer-middle'></div>

            <div className='layer-bottom'></div>

            <div className='login-panel'>
                <Form
                    ref={formRef}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '用户名不能为空' }]}
                    >
                        <Input
                            prefix={<i className='fm-icon-user' />}
                            bordered={false}
                            placeholder="请输入用户名"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password
                            prefix={<i className='fm-icon-lock' />}
                            bordered={false}
                            placeholder="请输入密码"
                            autoComplete='off'
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>记住密码</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            loading={loading}
                        >登录</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}