import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { post } from "../../../../utils/axios"
import moment from 'moment';

type NoticeItem = {
    ObjectID: string
    Url: string
    fbsj: string
    titel: string
    showType: string
}

export default ({ itemClick }: { itemClick: (type: string, url: string) => void }) => {
    const [noticeList, setNoticeList] = useState<NoticeItem[]>([])

    useEffect(() => {
        getNoticeList()
    }, [])

    // 获取公告列表
    const getNoticeList = () => {
        post({
            url: 'BpmQuery/QueryNoticeList',
            data: { NoticeType: '集团公告', PageSize: 10 }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data.List as NoticeItem[]
                    setNoticeList(data.map(item => {
                        item.fbsj = moment(item.fbsj).format('YYYY-MM-DD')
                        return item
                    }))
                }
            })
    }

    return (
        <section className="section-container" data-component-name="notice">
            <div className="section-header">
                <h2 className="title">集团公告</h2>
                <Link to={'/notice'} state={{ noticeType: '集团公告' }} className="more">更多&gt;&gt;</Link>
            </div>
            <div className="section-body">
                <ul className='list'>
                    {
                        noticeList.map(item => (
                            <li className='item' key={item.ObjectID} onClick={() => itemClick(item.showType, item.Url)}>
                                <div className='title h-ellipsis-1'>{item.titel}</div>
                                <span className='date'>{item.fbsj}</span>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': noticeList.length > 0 ? 'none' : 'block' }}
                    description="暂无相关公告"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}