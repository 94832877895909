import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _find from 'lodash/find';

interface systemState {
    id: string
    name: string
    url: string
    logo: string
    workItemCount?: number
    circulateItemCount?: number
    myWorkFlowCount?: number
}

// 初始化
const initialState: { list: systemState[] } = {
    list: []
}

// 计数器
export const systemSlice = createSlice({
    name: 'bpmSystem',
    initialState,
    reducers: {
        // 更新系统列表
        updateSystem: (state, action: PayloadAction<systemState[]>) => {
            state.list = action.payload
        },

        // 更新系统待办数量
        updateSystemWorkItemCount: (state, action: PayloadAction<{ id: string, count: number }>) => {
            const { id, count } = action.payload
            const system = _find(state.list, { id: id })
            system && (system.workItemCount = count)
        },

        // 更新系统待阅数量
        updateSystemCirculateItemCount: (state, action: PayloadAction<{ id: string, count: number }>) => {
            const { id, count } = action.payload
            const system = _find(state.list, { id: id })
            system && (system.circulateItemCount = count)
        },

        // 更新系统我的流程数量
        updateSystemMyWorkFlowCount: (state, action: PayloadAction<{ id: string, count: number }>) => {
            const { id, count } = action.payload
            const system = _find(state.list, { id: id })
            system && (system.myWorkFlowCount = count)
        },

        // 清空系统列表
        clearSystem: state => {
            state.list = []
        },
    },
})

export const {
    updateSystem,
    clearSystem,
    updateSystemWorkItemCount,
    updateSystemCirculateItemCount,
    updateSystemMyWorkFlowCount
} = systemSlice.actions

export default systemSlice.reducer