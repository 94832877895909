// 更新 LocalStorage
export const setLocalStorage = (key: string, value: any) => {
    const jsonStr = JSON.stringify(value)
    localStorage.setItem(key, jsonStr)
}

// 获取 LocalStorage
export const getLocalStorage = (key: string): any => {
    const data = localStorage.getItem(key)
    if (data) {
        return JSON.parse(data)
    }
}

// 清除 LocalStorage
export const clearLocalStorage = (key: string) => {
    localStorage.removeItem(key)
}

// 设置 Cookie
export const setBPMCookie = (cookie: string) => {
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    document.cookie = `${cookie} domain=${domain}; path=/; SameSite=Lax;`;
}

// 移除 Cookie
export const removeBPMCookie = (name: string) => {
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    document.cookie = `${name}=; domain=${domain}; expires=${new Date('2010')}; path=/;`;
}

// 下载文件
export const downloadFile = (fileName: string, url: string) => {
    console.log(fileName);
    if (/(\.pdf)/.test(fileName)) {
        window.open(url)
    } else {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = xhr.response
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(a.href);
            }
        };
        xhr.send();
    }
}