import { configureStore } from '@reduxjs/toolkit'
import currentUserReducer from './slices/currentUser'
import bpmSystemReducer from './slices/bpmSystem'

export const store = configureStore({
    reducer: {
        currentUser: currentUserReducer,
        bpmSystem: bpmSystemReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch