import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setLocalStorage, getLocalStorage, clearLocalStorage } from "../../utils";

interface TokenState {
    authInfo?: AuthInfo
    userInfo?: UserInfo
}

type AuthInfo = {
    token: string | undefined,
    time: number,
}

type UserInfo = {
    RealName: string | undefined,
}

// 初始化
const initialState: TokenState = {
    authInfo: getLocalStorage('currentuser_authinfo'),
    userInfo: getLocalStorage('currentuser_userinfo'),
}

// 当前登录用户
export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setAuthInfo: (state, action: PayloadAction<AuthInfo>) => {
            state.authInfo = action.payload
            setLocalStorage('currentuser_authinfo', state.authInfo)
        },

        delAuthInfo: state => {
            state.authInfo = undefined
            clearLocalStorage('currentuser_authinfo')
        },

        setUserInfo: (state, action: PayloadAction<UserInfo>) => {
            state.userInfo = action.payload
            setLocalStorage('currentuser_userinfo', state.userInfo)
        },

        delUserInfo: state => {
            state.userInfo = undefined
            clearLocalStorage('currentuser_userinfo')
        },
    },
})

export const { setAuthInfo, delAuthInfo, setUserInfo, delUserInfo } = currentUserSlice.actions

export default currentUserSlice.reducer