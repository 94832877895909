import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Space, Divider } from 'antd';
import { useAppSelector } from '../../../../store/hook'
import "./index.less"

export default () => {
    const navigate = useNavigate();
    const { state } = useLocation()
    const [targetPath, setTargetPath] = useState('/')
    const bpmSystemList = useAppSelector(state => state.bpmSystem.list)

    useEffect(() => {
        const { path } = (state as any) || {}
        if (path) {
            setTargetPath(path)
        } else {
            navigate('/')
        }
    }, [state])

    return (
        <div className="system">
            <div className='collection'>
                {
                    bpmSystemList.map(system => (
                        <div
                            className='item'
                            key={system.id}
                            onClick={() => navigate(targetPath, {
                                state: { id: system.id }
                            })}
                        >
                            <div className='item-container'>
                                <div className='info'>
                                    <Space split={
                                        <Divider
                                            type="vertical"
                                            style={{ "borderColor": "#949494", "borderWidth": "2px", "height": "2em" }}
                                        />
                                    }>
                                        <img className='logo' src={system.logo} />
                                        <span className='name'>{system.name}</span>
                                    </Space>
                                </div>

                                <div className='badge' style={{ "display": targetPath === '/workbench' ? 'flex' : 'none' }}>
                                    <Badge
                                        showZero
                                        count={system.workItemCount}
                                        offset={[17, 3]}
                                        style={{ "backgroundColor": '#c51b1e' }}
                                    >待办</Badge>

                                    <Badge
                                        showZero
                                        count={system.circulateItemCount}
                                        offset={[17, 3]}
                                        style={{ "backgroundColor": '#c51b1e' }}
                                    >待阅</Badge>
                                </div>
                            </div>

                            <div className='item-mask'>
                                <i className='fm-icon-arrow-right'></i>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}