import { useState } from 'react';
import NoticeSection from "./NoticeSection"
import NewsSection from "./NewsSection"
import "./index.less"
import NoticeModal from "../../../../components/NoticeModal";

export default () => {
    const [noticeData, setNoticeData] = useState({ visible: false, url: '' });

    // 点击公告
    const handleItemClick = (type: string, url: string) => {        
        if (type == '外部链接') {
            window.open(url, "_blank");
        } else {
            setNoticeData({
                visible: true,
                url: url
            })
        }
    }

    return (
        <div className="home">
            <NoticeSection itemClick={handleItemClick} />

            <NewsSection itemClick={handleItemClick} />

            <NoticeModal
                data={noticeData}
                closeModal={() => setNoticeData({ url: '', visible: false })}
            />
        </div>
    )
}