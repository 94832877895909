import { Routes, Route, Navigate } from "react-router-dom";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import "./index.less";

// 首页
import HomePage from './Home';
// 工作台
import WorkbenchPage from './Workbench';
// 报表中心
import ReportPage from './Report';
// 智能看板
import BoardPage from './Board';
// 公告
import NoticePage from './Notice';
// 标准化文件
import FilePage from './File';
// 选择系统
import SystemPage from './System';

export default () => {
    return (
        <div className="wrap">
            <PageHeader />

            <div className="container">
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="workbench/*" element={<WorkbenchPage />} />
                    <Route path="report" element={<ReportPage />} />
                    <Route path="board" element={<BoardPage />} />
                    <Route path="notice" element={<NoticePage />} />
                    <Route path="system" element={<SystemPage />} />
                    <Route path="file" element={<FilePage />} />
                    <Route path="*" element={<Navigate to='/' />} />
                </Routes>
            </div>

            <PageFooter />
        </div>
    )
}