import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { Empty, Pagination, Image } from 'antd';
import { post } from "../../../../utils/axios"
import moment from 'moment';
import './index.less'
import NoticeModal from "../../../../components/NoticeModal";
import FallbackImg from "./../../../../assets/images/fallback-img_1.jpg";

type NoticeItem = {
    ObjectID: string
    Url: string
    fbsj: string
    titel: string
    pimg: string
    zy: string
    showType: string
}

type PaginationType = {
    current: number
    total?: number
    pageSize: number
}

type FormDataFilter = {
    PageIndex: number
    PageSize: number
    NoticeType?: string
}

export default () => {
    const [noticeList, setNoticeList] = useState<NoticeItem[]>([])
    const [pager, setPager] = useState<PaginationType>({ current: 1, total: 0, pageSize: 10 }) // 分页器
    const location = useLocation()
    const formData =  useRef<FormDataFilter>({ PageIndex: 1, PageSize: 10 })
    const [noticeData, setNoticeData] = useState({ visible: false, url: '' });
    const [displayThumb, setDisplayThumb] = useState(false);

    useEffect(() => {
        const state = location.state as any
        if (state) {
            formData.current.NoticeType = state.noticeType
            setDisplayThumb(state.displayThumb)
        }
        getNoticeList()
    }, [])

    // 获取公告列表
    const getNoticeList = () => {
        post({
            url: 'BpmQuery/QueryNoticeList',
            data: formData.current
        })
            .then(res => {
                if (res.IsSuccess) {
                    const { List, Curpage, Datacount } = res.Data

                    setPager({
                        total: Datacount,
                        current: Curpage,
                        pageSize: formData.current.PageSize
                    })

                    const data = List as NoticeItem[]
                    setNoticeList(data.map(item => {
                        item.fbsj = moment(item.fbsj).format('YYYY-MM-DD')
                        return item
                    }))
                }
            })
    }

    // 点击公告
    const handleItemClick = (type: string, url: string) => {
        if (type == '外部链接') {
            window.open(url, "_blank");
        } else {
            setNoticeData({
                visible: true,
                url: url
            })
        }
    }

    return (
        <div className="notice">
            <div className='notice-list'>
                <div className='notice-list-head' style={{ 'display': displayThumb ? 'none' : 'block' }}></div>

                <div className='notice-list-body'>
                    {
                        noticeList.map(item => {
                            if (displayThumb) {
                                return (
                                    <div className='notice-item thumb' key={item.ObjectID} onClick={() => handleItemClick(item.showType, item.Url)}>
                                        <Image
                                            className='cover'
                                            preview={false}
                                            src={item.pimg || 'err'}
                                            fallback={FallbackImg}
                                        />
                                        <div className='content'>
                                            <div className='title h-ellipsis-1'>{item.titel}</div>
                                            <p className='summary'>{item.zy}</p>
                                        </div>
                                        <label className='date'>{item.fbsj}</label>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='notice-item' key={item.ObjectID} onClick={() => handleItemClick(item.showType, item.Url)}>
                                        <div className='content'>
                                            <div className='title h-ellipsis-1'>{item.titel}</div>
                                        </div>
                                        <label className='date'>{item.fbsj}</label>
                                    </div>
                                )
                            }
                        })
                    }

                    <Empty
                        style={{ 'display': noticeList.length > 0 ? 'none' : 'block' }}
                        description="暂无相关公告"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                </div>

                <div className='notice-list-foot'>
                    <Pagination
                        size="small"
                        total={pager.total}
                        current={pager.current}
                        pageSize={pager.pageSize}
                        showQuickJumper
                        hideOnSinglePage
                        showTotal={total => `共 ${total} 条`}
                        onChange={(page, pageSize) => {
                            formData.current.PageIndex = page
                            formData.current.PageSize = pageSize
                            getNoticeList()
                        }}
                    />
                </div>
            </div>

            <NoticeModal
                data={noticeData}
                closeModal={() => setNoticeData({ url: '', visible: false })}
            />
        </div>
    )
}