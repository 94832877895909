import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { Spin } from 'antd';
import "./index.less"
import { post } from "../../../../utils/axios"
import { useAppSelector } from '../../../../store/hook'
import _find from 'lodash/find';

const MyWorkflowPage = React.lazy(() => import('./MyWorkflow')) // 发起流程
const MyUnfinishedWorkItemPage = React.lazy(() => import('./MyUnfinishedWorkItem')) // 待办任务
const MyFinishedWorkItemPage = React.lazy(() => import('./MyFinishedWorkItem')) // 已办任务
const MyUnReadWorkItemPage = React.lazy(() => import('./MyUnReadWorkItem')) // 待阅任务
const MyReadWorkItemPage = React.lazy(() => import('./MyReadWorkItem')) // 已阅任务
const MyInstancePage = React.lazy(() => import('./MyInstance')) // 我的流程

export type WorkflowItem = {
    id: string
    text: string
    type: 'mulu' | 'workflow'
    IsForStartWf?: boolean
    IconUrl?: string
    children?: WorkflowItem[]
}

const createWorkflowTree = (item: WorkflowItem): WorkflowItem => {
    if (item.type === 'mulu') {
        return {
            id: item.id,
            text: item.text,
            type: 'mulu',
            children: item.children?.map(node => createWorkflowTree(node)),
        }
    } else {
        return {
            id: item.id,
            text: item.text,
            type: 'workflow',
            IsForStartWf: item.IsForStartWf,
            IconUrl: item.IconUrl,
        }
    }
}

export type OriginatorItem = {
    id: string
    text: string
    type: 'org' | 'user'
    selectable: Boolean
    children?: OriginatorItem[]
}

const createOriginatorTree = (item: OriginatorItem): OriginatorItem => {
    if (item.type === 'org') {
        return {
            id: item.id,
            text: item.text,
            type: 'org',
            selectable: false,
            children: item.children?.map(node => createOriginatorTree(node)),
        }
    } else {
        return {
            id: item.id,
            text: item.text,
            type: 'user',
            selectable: true,
        }
    }
}

type SystemItem = {
    id?: string
    workItemCount: number
    circulateItemCount: number
    myWorkFlowCount: number
}

export default () => {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const [workflowTree, setWorkflowTree] = useState<WorkflowItem[]>([])
    const [originatorTree, setOriginatorTree] = useState<OriginatorItem[]>([])
    const bpmSystemList = useAppSelector(state => state.bpmSystem.list)
    const [currentSystem, setCurrentSystem] = useState<SystemItem>({
        workItemCount: 0,
        circulateItemCount: 0,
        myWorkFlowCount: 0,
    })

    useEffect(() => {
        const { id } = (state as any) || {}
        if (id) {
            const system = _find(bpmSystemList, { id: id })
            if (system) {
                setCurrentSystem({
                    id: system.id,
                    workItemCount: system.workItemCount || 0,
                    circulateItemCount: system.circulateItemCount || 0,
                    myWorkFlowCount: system.myWorkFlowCount || 0,
                })
                getWorkflowList(system.id)
                getOriginatorTree(system.id)
            }
        } else {
            navigate('/system', { state: { target: '/workbench' } })
        }
    }, [bpmSystemList])

    // 获取流程模型信息列表
    const getWorkflowList = (systemId?: string) => {
        post({
            url: 'BpmQuery/QueryWorkFlowList',
            data: { BpmConfigEntityId: [systemId] }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const [{ IsSuccess, Data }] = res.Data.List
                    if (IsSuccess) {
                        const data: WorkflowItem[] = Data.map((item: any) => createWorkflowTree(item))
                        setWorkflowTree(data)
                    }
                }
            })
    }

    // 获取组织机构及用户信息
    const getOriginatorTree = (systemId?: string) => {
        post({
            url: 'BpmQuery/GetOrgAndUserTree',
            data: { BpmConfigEntityId: [systemId] }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const [{ IsSuccess, Data }] = res.Data.List
                    if (IsSuccess) {
                        const data: OriginatorItem[] = Data.map((item: any) => createOriginatorTree(item))
                        setOriginatorTree(data)
                    }
                }
            })
    }

    return (
        <div className="workbench">
            {/* 左侧区域 */}
            <aside className="left-area">
                <ul className="sidebar-menu">
                    <li
                        className={pathname === '/workbench' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench', {
                            state: { id: currentSystem?.id }
                        })}
                    >发起流程</li>
                    <li
                        className={pathname === '/workbench/MyUnfinishedWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyUnfinishedWorkItem', {
                            state: { id: currentSystem?.id }
                        })}
                    >待办任务{currentSystem.workItemCount > 0 && <label className="badge">({currentSystem.workItemCount >= 100 ? '99+' : currentSystem.workItemCount})</label>}</li>
                    <li
                        className={pathname === '/workbench/MyFinishedWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyFinishedWorkItem', {
                            state: { id: currentSystem?.id }
                        })}
                    >已办任务</li>
                    <li
                        className={pathname === '/workbench/MyUnReadWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyUnReadWorkItem', {
                            state: { id: currentSystem?.id }
                        })}
                    >
                        待阅任务{currentSystem.circulateItemCount > 0 && <label className="badge">({currentSystem.circulateItemCount >= 100 ? '99+' : currentSystem.circulateItemCount})</label>}
                    </li>
                    <li
                        className={pathname === '/workbench/MyReadWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyReadWorkItem', {
                            state: { id: currentSystem?.id }
                        })}
                    >已阅任务</li>
                    <li
                        className={pathname === '/workbench/MyInstance' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyInstance', {
                            state: { id: currentSystem?.id }
                        })}
                    >
                        我的流程{currentSystem.myWorkFlowCount > 0 && <label className="badge">({currentSystem.myWorkFlowCount >= 100 ? '99+' : currentSystem.myWorkFlowCount})</label>}
                    </li>
                </ul>
            </aside>

            {/* 右侧区域 */}
            <main className="right-area">
                <Suspense fallback={<Spin tip="加载中..." />}>
                    <Routes>
                        <Route index element={<MyWorkflowPage systemId={currentSystem.id} workflowTreeData={workflowTree} />} />
                        <Route path="MyWorkflow" element={<MyWorkflowPage systemId={currentSystem.id} workflowTreeData={workflowTree} />} />
                        <Route path="MyUnfinishedWorkItem" element={<MyUnfinishedWorkItemPage systemId={currentSystem.id} workflowTreeData={workflowTree} originatorTreeData={originatorTree} />} />
                        <Route path="MyFinishedWorkItem" element={<MyFinishedWorkItemPage systemId={currentSystem.id} workflowTreeData={workflowTree} originatorTreeData={originatorTree} />} />
                        <Route path="MyUnReadWorkItem" element={<MyUnReadWorkItemPage systemId={currentSystem.id} workflowTreeData={workflowTree} originatorTreeData={originatorTree} />} />
                        <Route path="MyReadWorkItem" element={<MyReadWorkItemPage systemId={currentSystem.id} workflowTreeData={workflowTree} originatorTreeData={originatorTree} />} />
                        <Route path="MyInstance" element={<MyInstancePage systemId={currentSystem.id} workflowTreeData={workflowTree} originatorTreeData={originatorTree} />} />
                        <Route path="*" element={<Navigate to='/' />} />
                    </Routes>
                </Suspense>
            </main>
        </div>
    )
}