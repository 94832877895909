import { useState, useEffect } from 'react';
import { Space, Divider } from 'antd';
import { useAppSelector } from '../../../../store/hook'
import "./index.less"
import { post } from "../../../../utils/axios"
import _find from 'lodash/find';

export default () => {
    const [biList, setBiList] = useState<{ id: string, url: string }[]>([])
    const bpmSystemList = useAppSelector(state => state.bpmSystem.list)

    useEffect(() => {
        getBiMainData()
    }, [])

    // 获取智能看板数据
    const getBiMainData = () => {
        post({
            url: 'BpmReport/GetBiMain',
        })
            .then(res => {
                const { IsSuccess, Data: { List } } = res
                if (IsSuccess) {
                    const data: any[] = (List as any[])
                        .filter(item => item.IsSuccess)
                        .map(item => ({
                            id: item.SystemId,
                            url: item.Data,
                        }))
                    setBiList(data)
                }
            })
    }

    // 智能看板是否可见
    const isBiVisible = (systemId: string) => {
        return _find(biList, { id: systemId })
    }

    // 打开智能看板
    const openBiPage = (systemId: string) => {
        const bi = _find(biList, { id: systemId })
        if (bi) {
            window.open(bi.url, "_blank")
        }
    }

    return (
        <div className="board">
            <div className='collection'>
                {
                    bpmSystemList.map(system => (
                        <div
                            className='item'
                            style={{ "display": isBiVisible(system.id) ? 'block' : 'none' }}
                            key={system.id}
                            onClick={() => openBiPage(system.id)}
                        >
                            <div className='item-container'>
                                <div className='info'>
                                    <Space split={
                                        <Divider
                                            type="vertical"
                                            style={{ "borderColor": "#949494", "borderWidth": "2px", "height": "2em" }}
                                        />
                                    }>
                                        <img className='logo' src={system.logo} />
                                        <span className='name'>{system.name}</span>
                                    </Space>
                                </div>
                            </div>

                            <div className='item-mask'>
                                <i className='fm-icon-arrow-right'></i>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}