import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../store/hook'
import { delAuthInfo, delUserInfo, setAuthInfo } from '../store/slices/currentUser'
import {
  updateSystem,
  clearSystem,
  updateSystemCirculateItemCount,
  updateSystemWorkItemCount,
  updateSystemMyWorkFlowCount
} from '../store/slices/bpmSystem'
import './App.less';
import { post } from '../utils/axios'
import qs from 'qs';

// 初始布局
import IndexLayout from '../views/pages/Index';
// 登录页面
import LoginPage from '../views/pages/Login';

export default () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch()
  const authInfo = useAppSelector(state => state.currentUser.authInfo)
  const subTokens: string[] = []
  const timers: NodeJS.Timer[] = []
  const [bpmList, setBpmList] = useState<{ url: string, id: string }[]>([]);

  useEffect(() => {
    // 检查用户登录
    checkUserToken()

    /**
     * 消息订阅
     * 
     * - 用户登录
     * - 用户退出
     * - 刷新登录 token
     * - 浏览器标签页切换
     */
    subTokens.push(PubSub.subscribe('UserLoginEvent', pageInitialize))
    subTokens.push(PubSub.subscribe('UserLogoutEvent', userLogout))
    subTokens.push(PubSub.subscribe('RefreshAuthInfoEvent', refreshAuthInfo))
    subTokens.push(PubSub.subscribe('BrowserTabsToggleEvent', updateDataCount))

    return () => {
      /**
       * 退订所有消息
       */
      for (let i = subTokens.length - 1; i >= 0; i--) {
        PubSub.unsubscribe(subTokens[i])
        subTokens.pop()
      }
    }
  }, []);

  useEffect(() => {
    if (bpmList.length > 0) {
      updateDataCount()
    }
  }, [bpmList]);

  // 检查用户登录
  const checkUserToken = () => {
    if (authInfo?.token) {
      // 用户已登录, 跳转到首页界面
      if (pathname === '/login') {
        const { autologin } = qs.parse(search.slice(1))
        autologin != 'false' ? navigate('/') : navigate('/login')
      }
      // 页面初始化
      pageInitialize()
    } else {
      // 用户未登录, 跳转到登录界面
      if (pathname !== '/login') {
        navigate('/login')
      }
    }
  }

  // 页面初始化
  const pageInitialize = () => {
    loginBpm()
    /**
     * 定时器
     * 
     * - 定时更新BPM
     */
    timers.push(setInterval(loginBpm, 20 * 1000 * 60))
  }

  // 登录 BPM
  const loginBpm = () => {
    post({ url: 'Login/GetBpmSystemList' }, true)
      .then(res => {
        const { IsSuccess, Data } = res
        if (IsSuccess) {
          const systemList: any[] = []
          const bpmList: any[] = []
          for (let i = 0; i < Data.length; i++) {
            const { BpmConfigEntityId, SystemLoginPage, SystemName, tempUrl, IconUrl } = Data[i];

            bpmList.push({
              id: BpmConfigEntityId,
              url: tempUrl,
            })

            systemList.push({
              id: BpmConfigEntityId,
              name: SystemName,
              url: SystemLoginPage,
              logo: IconUrl,
              workItemCount: 0,
              circulateItemCount: 0,
              myWorkFlowCount: 0,
            })
          }
          setBpmList(bpmList)
          dispatch(updateSystem(systemList))
        }
      })
  }

  // 更新 待办、待阅、我的流程 数据数量
  const updateDataCount = () => {
    const systemIds = bpmList.map(bpm => bpm.id)

    for (let i = 0; i < systemIds.length; i++) {
      const systemId = systemIds[i]
      Promise.all([
        // 获取待办信息
        post({
          url: 'BpmQuery/QueryWorkItemList',
          data: { PageSize: 1, BpmConfigEntityId: [systemId] }
        }, true),
        // 获取待阅信息
        post({
          url: 'BpmQuery/QueryCirculateItemList',
          data: { PageSize: 1, BpmConfigEntityId: [systemId] }
        }, true),
        // 获取我的流程信息
        post({
          url: 'BpmQuery/QueryMyWorkFlowList',
          data: { PageSize: 1, BpmConfigEntityId: [systemId] }
        }, true),
      ])
        .then(([workItemList, circulateItemList, myWorkFlowList]) => {
          if (workItemList.IsSuccess) {
            dispatch(updateSystemWorkItemCount({
              id: systemId,
              count: workItemList.Data.Datacount
            }))
          }
          if (circulateItemList.IsSuccess) {
            dispatch(updateSystemCirculateItemCount({
              id: systemId,
              count: circulateItemList.Data.Datacount
            }))
          }
          if (myWorkFlowList.IsSuccess) {
            dispatch(updateSystemMyWorkFlowCount({
              id: systemId,
              count: myWorkFlowList.Data.Datacount
            }))
          }
        });
    }
  }

  // 刷新登录 token
  const refreshAuthInfo = (msg: string, data: any) => {
    dispatch(setAuthInfo({
      token: data.token,
      time: data.time,
    }))
  }

  // 用户退出门户
  const userLogout = () => {
    setBpmList([])
    // 清除所有定时器
    for (let i = timers.length - 1; i >= 0; i--) {
      clearInterval(timers[i])
      timers.pop()
    }

    dispatch(clearSystem())
    dispatch(delAuthInfo())
    dispatch(delUserInfo())

    navigate('/login')
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/*" element={<IndexLayout />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Navigate to='/' />} />
      </Routes>

      {
        bpmList.map(bpm => (
          <iframe key={bpm.id} src={bpm.url} style={{ "display": "none" }}></iframe>
        ))
      }
    </div>
  );
};
