import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './views/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);

document.addEventListener('visibilitychange', () => {
  // 用户回到页面
  if (!document.hidden) {
    // 发送浏览器标签页切换事件
    PubSub.publish('BrowserTabsToggleEvent')
  }
}, false)