import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, notification } from 'antd';
import "./index.less"
import { post } from "../../../../utils/axios"

type ReportItem = {
    ModuleEntityId: string
    ModuleName: string
    ModuleTargetType: number
    UrlAddress?: string
    children?: ReportItem[]
}

const createReportTree = (item: ReportItem): ReportItem => {
    if (item.ModuleTargetType === 1) {
        return {
            ModuleEntityId: item.ModuleEntityId,
            ModuleName: item.ModuleName,
            ModuleTargetType: 1,
            children: item.children?.map(node => createReportTree(node)),
        }
    } else {
        return {
            ModuleEntityId: item.ModuleEntityId,
            ModuleName: item.ModuleName,
            ModuleTargetType: 2,
            UrlAddress: item.UrlAddress
        }
    }
}

export default () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [systemId, setSystemId] = useState()
    const [reportMenuTree, setReportMenuTree] = useState<ReportItem[]>([])

    useEffect(() => {
        const { id } = (state as any) || {}
        if (id) {
            setSystemId(id)
        } else {
            navigate('/system', { state: { target: '/report' } })
        }
    }, [])

    useEffect(() => {
        if (systemId) {
            getReportMenuTree()
        }
    }, [systemId])

    // 获取 BPM 报表信息树
    const getReportMenuTree = () => {
        post({
            url: 'BpmReport/GetReportMenuTree',
            data: { BpmConfigEntityId: [systemId] }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const [{ IsSuccess, Data }] = res.Data.List
                    if (IsSuccess) {
                        let data: ReportItem[] = Data.map((item: any) => createReportTree(item))
                        // 过滤菜单报表
                        const menuList = data.filter(item => item.children)
                        // 过滤报表
                        const reportList = data.filter(item => !item.children)
                        // 将没有菜单的报表, 归类到 "其他" 菜单下
                        if (reportList.length > 0) {
                            menuList.push({
                                ModuleEntityId: 'other',
                                ModuleName: '其他',
                                ModuleTargetType: 1,
                                children: reportList
                            })
                        }
                        setReportMenuTree(menuList)
                    }
                }
            })
    }

    return (
        <div className="report">
            <Row gutter={20}>
                {
                    reportMenuTree.map(menu => (
                        <Col className='collection__item' span={8} key={menu.ModuleEntityId}>
                            <div className='card'>
                                <div className='card-header'>
                                    <i className='fm-icon-chart icon'></i>
                                    <div className='title h-ellipsis-1'>{menu.ModuleName}</div>
                                </div>
                                <div className='card-body'>
                                    {
                                        menu.children?.map(item => (
                                            <p
                                                className={item.UrlAddress && item.UrlAddress.length > 0 ? "list__cell" : 'list__cell disabled'}
                                                key={item.ModuleEntityId}
                                                onClick={() => {
                                                    if (item.UrlAddress && item.UrlAddress.length > 0) {
                                                        window.open(item.UrlAddress, '_blank')
                                                    } else {
                                                        notification.error({
                                                            message: '提示',
                                                            description: '访问权限'
                                                        })
                                                    }
                                                }}
                                            >
                                                {item.ModuleName}
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}